.bem_advantage {
  background: no-repeat center top;
  padding-top: 77px;
  margin-bottom: 64px;

  &_type {
    &_diagram {
      background-image: url(../img/ico__diagram.png);
    }
    
    &_wallet {
      background-image: url(../img/ico__wallet.png);
    }
    
    &_zero-percent {
      background-image: url(../img/ico__zero-percent.png);
    }
    
    &_thumb-up {
      background-image: url(../img/ico__thumb-up.png);
    }
    
    &_timer {
      background-image: url(../img/ico__timer.png);
    }
    
    &_24-hours {
      background-image: url(../img/ico__24-hours.png);
    }
    
    &_card-in-hand {
      background-image: url(../img/ico__card-in-hand.png);
    }
    
    &_client {
      background-image: url(../img/ico__client.png);
    }
  }
}