.bem_offers {
  &__text {
    padding: 0 67px;

    @media (max-width: 769px) {
      padding: 0;
    }

    @media (max-width: 480px) {
      margin-top: 41px;
    }
  }
}