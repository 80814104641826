$strong-color: #00a0d5;
.bem_header {

  &__left {
    margin-top: 28px;

    a {
      line-height: 1;
    }

    @media (max-width: 769px) {
      margin-top: 17px;
    }
  }

  &__content {
    border-bottom: 1px solid rgba(186, 186, 186, 0.1);
    padding-bottom: 22px;

    @media (max-width: 769px) {
      padding-bottom: 17px;
    }

    @media (max-width: 480px) {
      border-bottom: 0;
    }
  }

  &__center {
    font-size: 16px;
    text-align: center;
    margin-top: 28px;

    strong {
      display: block;
      color: $strong-color;
      font-size: 20px;
      font-weight: 700;

      @media (max-width: 769px) {
        font-size: 14px;
      }

      @media (max-width: 480px) {
        margin-top: 0;
        display: inline;
      }
    }

    @media (max-width: 769px) {
      font-size: 14px;
      margin-top: 17px;
    }
  }

  &__right {
    font-size: 16px;
    text-align: right;
    margin-top: 28px;

    strong {
      color: $strong-color;
      font-weight: 700;

      @media (max-width: 769px) {
        font-size: 14px;
      }
    }

    @media (max-width: 769px) {
      font-size: 14px;
      margin-top: 17px;
    }

    @media (max-width: 480px) {
      background-color:  rgba(186, 186, 186, 0.1);
      margin-left: -15px;
      margin-right: -15px;
      margin-top: 0;
      text-align: center;
    }
  }
}