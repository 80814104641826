@import "bootstrap-custom";
@import "base";
/*@import "typography";*/
@import "main";
@import "parallelogram";
@import "bem_offer";
@import "bem_advantage";
@import "bem_advantages";
@import "bem_header";
@import "bem_footer";
@import "bem_offers";
@import "bem_param";
@import "button";
/*
@import "bem_foot";
@import "trebovaniya";
@import "banner-wrapper";
*/
