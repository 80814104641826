.bem_param {
  background: left center no-repeat;
  padding-left: 30px;
  font-size: 16px;

  &_type {
    &_coin {
      background-image: url(../img/ico__coins.png);
    }

    &_percent {
      background-image: url(../img/ico__percent.png);
    }

    &_age {
      background-image: url(../img/ico__age.png);
    }
  }
}