.bem_footer {
  &__content {
    background: #00a0d5;
    padding: 12px 0;
  }

  &__copy {
    color: #fff;
    padding-left: 67px;

    @media (max-width: 769px) {
      padding-left: 0;
    }
  }
}