.bem_advantages {
  padding-top: 60px;

  @media (max-width: 769px) {
    padding-top: 30px;
  }

  &__headline {
    color: #1a1919;
    text-align: center;
    margin-bottom: 47px;

    @media (max-width: 480px) {
      margin-bottom: 26px;
    }
  }
}