@charset "utf-8";
* {
  margin: 0;
  padding: 0;
}

body {
  font: 14px/1.4 'PT Sans', Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: #616060;
}

h1, h2, h3, h4, h5, h6, p {
  margin-bottom: 10px;
}

h1 {
  color: #1a1919;
  margin-bottom: 40px;
}

a img {
  border: none;
}

a:link {
  color: #A41C7E;
  text-decoration: underline;
}

a:visited {
  color: #A41C7E;
  text-decoration: underline;
}

a:hover, a:active, a:focus {
  text-decoration: none;
}

.clr {
  clear: both;
  height: 0;
  width: 0;
}

#top-container {
  overflow: hidden;
  margin-bottom: 72px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
}

.header-t {
  height: 58px;
  line-height: 58px;
}

.head-t {
  width: 300px;
  float: left;
}

.head-s {
  width: 690px;
  float: right;
  text-align: right;
  padding-top: 18px;
}


.header-f-w, .header-t-w {
  width: 990px;
  margin: 0 auto;
  padding: 0 7px;
}

.head-logo {
  padding: 10px 0;
  line-height: 60px;

  @media (max-width: 769px) {
    padding: 0;
  }

  @media (max-width: 480px) {
    text-align: center;
  }
}

.head-logo a {
  text-decoration: none;
  font-size: 22px;
  color: #232323;
  font-weight: 500;
  display: block;
  line-height: 60px;
}

.head-z {
  float: right;
  margin-top: 24px;

  @media (max-width: 768px) {
    display: none;
  }
}

#numer, #zajz {
  float: left;
}

#numer span {
  background: url("../img/numm.png") repeat-x scroll center top #FFFFFF;
  border-radius: 5px;
  font-size: 24px;
  margin-left: 5px;
  padding: 2px 4px;
  color: #eee;
}

#zajz {
  margin-left: 10px;
  margin-top: 5px;
}

#container {
}

.content {
  width: 990px;
  margin: 0 auto;
  padding: 0 7px;
  overflow: hidden;
}

.content-l {
  float: left;
  width: 460px;
  padding-top: 20px;
}

.z-p {
  font-size: 18px;
}

.ui-slider {
  border: 1px solid #C6C6C6 !important;
  border-radius: 4px !important;
  height: 7px !important;
  background: none repeat scroll 0 0 #EEEEEE !important;
  margin: 25px 2px 35px !important;
}

.ui-slider-handle {
  background: url("../images/slider-icon-amount.png") no-repeat scroll 50% 50% #A41C7E !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  height: 31px !important;
  outline: medium none !important;
  top: -13px !important;
  width: 31px !important;
  border: none !important;
}

#slider-range-min1 .ui-slider-handle {
  background: url("../images/slider-icon-term.png") no-repeat scroll 50% 50% #A41C7E !important;
}

.ui-slider-range {
  background: none repeat scroll 0 0 #A41C7E !important;
  border-radius: 2px !important;
}

div.button {
}

div.button a {
  color: #fff;
  text-decoration: none;
  background-color: #A41C7E;
  margin: 50px 0;
  display: block;
  height: 40px;
  width: 180px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}

.content-r {
  float: right;
  width: 530px;
  margin-top: 5px;
}

#container-a {
  background: none repeat scroll 0 0 #F1F1F1;
  height: 80px;
  border-bottom: 2px solid rgba(1, 1, 1, 0.075);
  border-top: 1px solid rgba(1, 1, 1, 0.05);
}

#container-a .content {
  line-height: 80px;
  text-align: center;
}

#container-b {
  padding: 3em 0;
}

#container-b .content {
}

.nav-1, .nav-2, .nav-3, .nav-4 {
  float: left;
  width: 25%;
}

#container-b span.back {
  height: 130px;
  width: 130px;
  display: block;
  margin: 0 auto 22px;
  border-radius: 50%;
  background-color: #A41C7E;
}

#container-b span.back img {
}

#container-b .title {
  text-align: center;
  display: block;
  width: 130px;
  margin: 0 auto;
  font-size: 16px;
}

#container-с {
  border-bottom: 1px solid #E7E7E7;
  padding: 3em 0 0;
  font-size: 13px;
}

#container-с .content-r {
  width: 570px;
  margin-left: 80px;
  padding-top: 20px;
}

#container-с .content-l {
  width: 340px;
  padding-top: 0;
}

.text-info-t {
  padding: 15px;
  background-color: #A41C7E;
  margin-bottom: 20px;
  border-radius: 8px;
}

.text-info-t h2 {
  margin: 0;
  font-size: 18px;
  color: #FFF;
}

#container-с .content-r p {
  padding: 0 5px;
}

#container-e {
  background: none repeat scroll 0 0 #F1F1F1;
  border-top: 1px solid rgba(1, 1, 1, 0.05);
  padding: 3em 0;
}

#container-d {
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-top: 1px solid rgba(1, 1, 1, 0.05);
}

.content-e-t {
  overflow: hidden;
  border-bottom: 1px solid rgba(1, 1, 1, 0.075);
  padding-bottom: 3em;
}

.nav-5, .nav-6, .nav-7 {
  float: left;
  width: 33%;
}

.nav-t {
  padding-left: 90px;
  position: relative;
  height: 70px;
  font-size: 16px;
}

.nav-t span {
  position: absolute;
  left: 0;
  display: block;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #fff;
  font-size: 40px;
  background-color: #A41C7E;
  line-height: 70px;
  text-align: center;
  font-family: arial, sans-serif;
  font-weight: bold;
}

.nav-t p {
  padding-top: 8px;
}

.content-e-f {
  padding: 3em 0 0;
}

.content-e-f img {
  margin-right: 20px;
}

#form-credit input[type="text"], .city_container .city_search input[type="text"] {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  color: #676767;
  height: 29px;
  line-height: 29px;
  outline: 0 none;
  padding: 0 5%;
  width: 90%;
}

#form-credit input[type="submit"], .city_container .city_search input[type="submit"] {
  -moz-box-sizing: border-box;
  background-image: -moz-linear-gradient(90deg, #E5E5E5 0%, #FFFFFF 100%);
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  color: #7CA411;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-style: italic;
  height: 30px;
  line-height: 30px;
  margin: 11px 0 0 10px;
  outline: 0 none;
  padding: 0 20px;
  text-align: center;
  text-decoration: none !important;
  text-shadow: 0 1px 0 #E9E9E9;
  width: 212px;
}

.city_container {
  margin-left: -16px;
}

.city_container .city_name {
  display: inline-block;
  padding: 0;
  margin: 0 20px;
  font-size: 33px;
  line-height: 29px;
  color: #84bd32;
  border-bottom: 2px #84bd32 dashed;
}

.city_container .city_frame {
  height: 337px;
  width: 573px;
  padding: 25px;
  top: -22px;
  left: -20px;
  border-radius: 6px;
  background-image: -moz-linear-gradient(90deg, rgb(229, 229, 229) 0%, rgb(255, 255, 255) 100%);
  background-image: -webkit-linear-gradient(90deg, rgb(229, 229, 229) 0%, rgb(255, 255, 255) 100%);
  background-image: -ms-linear-gradient(90deg, rgb(229, 229, 229) 0%, rgb(255, 255, 255) 100%);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
}

.city_container .close_frame {
  height: 25px;
  width: 25px;
  top: 25px;
  right: 24px;
  background: url(../images/close_modal.png) no-repeat;
}

.city_container .city_search {
  width: 100%;
  margin: 52px 0 12px;
}

.city_container .city_search input[type=text] {
  width: 377px;
  height: 39px;
  line-height: 39px;
  float: left;
  padding: 0 10px;
}

.city_container .city_search input[type=submit] {
  width: 150px;
  height: 38px;
  padding-bottom: 3px;
  line-height: 38px;
  float: right;
  margin: 0;
  font-size: 24px;
  font-style: normal;
  text-transform: lowercase;
}

.city_container .city_region {
  height: 234px;
  overflow: auto;
}

.city_container .city_table td > div a {
  display: inline;
  font-size: 20px;
  line-height: 30px;
  color: #333;
  border-bottom: 2px #84bd32 dashed;
}

.city_container .city_table td > div.active a {
  color: #84bd32;
  text-decoration: none;
}

div.search_b_1 input, div.search_b_2 select, div.search_k input, div.search_k select {
  border-radius: 6px;
}

#geo_zag h1 {
  font-family: arial, sans-serif;
  font-size: 26px;
  font-weight: normal;
}

.offers-block {
  margin-bottom: 30px;
  overflow: hidden;
}

.link_offers:hover {
  cursor: pointer
}

.cr_block {
  margin-top: 10px;
  padding: 20px;
  width: 948px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #ffffff; /* Old browsers */
}

#container .content #geocredit_content #programs_block .cr_block:hover {
  border: 1px solid #00b56a !important
}

#cr_head {
  float: left;
}

.cr_img {
  float: left;
  width: 144px;
  padding-right: 56px;
  height: 60px;
}

#cr_zag {
  width: 123px;
  float: left;
  border-left: 1px solid #ccc;
  padding: 10px 0px 10px 20px;
  font-size: 15px;
  line-height: 20px;
  color: #333;
  height: 40px;
}

#cr_proc {
  width: 166px;
  float: left;
  text-align: center;
  margin-top: 10px;
  padding-top: 25px;
  height: 45px;
}

#cr_proc span.pr {
  color: #676767;
  font-size: 33px;
}

#cr_proc span.des {
  font-style: italic;
  color: #999;
  font-size: 12px;
}

.cr_cont {
  float: left;
}

.cr_p1, .cr_p3 {
  width: 160px;
  float: left;
  margin-top: 7px;
  font-weight: bold;
  color: #333333;
  font-size: 14px;
}

#cr_p2, .cr_p4, #cr_p5 {
  width: 230px;
  float: left;
  color: #676767;
  font-size: 13px;
  margin-top: 3px;
}

#cr_p5 {
  width: 264px !important;
}

.cr_pzag {
  color: #999;
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
}

.cr_block .button {
  float: right;
  text-align: center;
  line-height: 42px;
  background-color: #00b56a;
  border-radius: 4px;
  color: #FFFFFF;
  margin: 9px auto 0;
  width: 160px;
}

#needmore {
  font-size: 16px;
  margin: 5px 0;
}

#content .c_top_a .mod_content li, #geocredit_content ul.menu li {
  float: left;
  margin-right: 18px;
}

#geo_zag h1 {
  margin-bottom: 6px;
}

#geocredit_content ul.menu {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

#geocredit_content {
  margin-bottom: 30px;
}

#content .c_top_a, #geocredit_content .moduletable1 {
  font: 18px/33px Arial, "Nimbus Sans L", Helvetica, sans-serif;
  margin-bottom: 31px;
}

#footer ul {
  list-style: none;
}

#footer a {
  font-size: 12px;
  color: #eee;
}

.foot-1 {
  float: left;
  width: 400px;
}

.foot-2, .foot-3 {
  text-align: right;
  
  @media (max-width: 768px) {
    text-align: left;
  }
}

.foot-3 ul {
  text-align: right;
}

.foot-3 li {
  display: inline-block;
  margin-left: 15px;
}

/* ~~ Селекторы элементов/тегов ~~ */
ul, ol, dl { /* Из-за различий между браузерами рекомендуется обнулять поля в списках. Для согласованности можно указать нужные величины либо здесь, либо в элементах списка (LI, DT, DD), которые они содержат. Помните, что сделанное здесь последовательно включается в список .nav, если только не будет прописан более конкретный селектор. */
  padding: 0;
  margin: 0;
}

/* ~~ этот контейнер окружает все остальные контейнеры DIV, задавая их ширину на процентной основе ~~ */

/* ~~ Этот сгруппированный селектор выдает списки в пространстве .content ~~ */
.content ul, .content ol {
  padding: 0 15px 15px 40px; /* это поле зеркально повторяет правое поле в правиле для заголовков и параграфов выше. Внизу поле помещено как граница между элементами списков, а слева — как отступ. Поля можно настраивать по желанию. */
}

/* ~~ прочие классы float/clear ~~ */
.fltrt { /* этот класс можно использовать для обтекания элемента справа на странице. Обтекаемый элемент должен предшествовать элементу, с которым он должен находиться рядом на странице. */
  float: right;
  margin-left: 8px;
}

.fltlft { /* этот класс можно использовать для обтекания элемента слева на странице. Обтекаемый элемент должен предшествовать элементу, с которым он должен находиться рядом на странице. */
  float: left;
  margin-right: 8px;
}

.clearfloat { /* этот класс можно поместить в теге <br /> или в пустом блоке DIV в качестве конечного элемента, следующего за последним обтекаемым DIV (внутри #container), если overflow:hidden удалено в .container */
  clear: both;
  height: 0;
  font-size: 1px;
  line-height: 0px;
}

div#foxcontainer_c127 {
  margin: 0 !important;
  padding: 0 !important;
}

#t3Form .panel-heading {
  background-color: #eee;
  background-image: none;
  border-color: #eee;
  color: #333;
}

#t3Form .panel-info {
  border-color: #ccc;
}

#t3Form .btn-primary {
  background-color: #A41C7E;
  background-image: none;
  background-repeat: repeat-x;
  border-color: #A41C7E;
  color: #FFFFFF;
}

.item-page, .item-separator {
  margin-bottom: 30px;
}

.item-page_reestr table {
  width: 100%;
}

.item-page_reestr table td, .item-page_reestr table th {
  padding: 5px;
  border: 1px solid #eee;
}

div#foxcontainer_c139 {
  margin-bottom: 40px;
  overflow: hidden;
}

div#foxcontainer_c139 .control-group {
  margin-bottom: 15px;
}

div#foxcontainer_c139 .control-group select {
  height: 28px;
  width: 270px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-radius: 3px;
}

div#foxcontainer_c139 .control-group input {
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  border-radius: 3px;
}

div#foxcontainer_c139 .control-group button {
  background-color: #a41c7e;
  background-image: none;
  background-repeat: repeat-x;
  border-color: #a41c7e;
  color: #ffffff;
  height: 32px;
  width: 100px;
  text-align: center;
}