.bem_offer {
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 21px;
  padding: 20px;
  position: relative;
  border: 1px solid transparent;
  text-decoration: none;
  transition: 0.2s;

  @media (max-width: 997px) {
    display: inline-block;
    vertical-align: top;
    width: 47%;
    margin-right: 5%;
    margin-bottom: 5%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    display: block;
    width: auto;
    margin-right: 0;
    padding: 15px;
  }

  &:link,
  &:visited {
    color: #616060;
    text-decoration: none;
  }

  &:hover {
    border-color: #f6c361;
  }

  &:hover & {
    &__note {
      background: #f19f00;
    }

    &__get {
      .button {
        background-color: #f19f00;
      }
    }
  }

  &__note {
    background: -webkit-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: -moz-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: -o-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: -ms-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: #00a0d5;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px 7px;
    position: absolute;
    left: 9px;
    line-height: 1;
    transition: 0.2s;
    text-transform: uppercase;
    top: 0;
  }

  &__info,
  &__image,
  &__text,
  &__get {
    vertical-align: top;
    width: 25%;
    font-size: 14px;

    @media (max-width: 769px) {
      width: auto;
    }
  }

  &__info {
    vertical-align: middle;
    max-width: 158px;
    margin: 0 auto;

    @media (max-width: 769px) {
      margin-bottom: 24px;
    }

    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 16px;

    span {
      color: #00a0d5;
    }

    @media (max-width: 769px) {
      text-align: center;
      margin-bottom: 20px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__image {
    padding: 0;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 997px) {
      display: block;
      width: auto;
      margin-right: 0;
      margin-bottom: 15px;
    }

    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }

  &__get {
    text-align: right;
    vertical-align: middle;
    display: flex;
    align-items: center;

    @media (max-width: 1025px) {
      width: 19%;
    }

    @media (max-width: 997px) {
      text-align: center;
      display: block;
      width: auto;
    }
  }
}